import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import PartnerTextBox from 'components/forms/PartnerTextBox.js';
import InvoiceItemList from 'components/documents/invoice/InvoiceItemList';
import Attribution from 'components/documents/invoice/Attribution.js';
import SignatureBox from 'components/forms/SignatureBox';
import InvoiceMetadata from 'components/documents/invoice/InvoiceMetadata';
import InvoiceOrganization from 'components/documents/invoice/InvoiceOrganization.js';
import InvoiceAttachmentList from 'components/documents/invoice/InvoiceAttachmentList';
import RelatedDocumentList from 'components/documents/invoice/RelatedDocumentList';
import * as Routes from 'constants/Routes.js';
import Router from 'utils/Router.js';
import useInvoice from 'hooks/useInvoice.js';
import InvoicePdfGenerator from 'components/documents/invoice/InvoicePdfGenerator.js';
import eSlog20Generator from 'utils/eSlog20Generator.js';
import useSnackbar from 'hooks/useSnackbar.js';
import EmailModal from 'components/modals/EmailModal';
import InvoiceHistoryModal from 'components/modals/InvoiceHistoryModal';
import { useAuth } from 'utils/Auth';
import InvoiceTypeLocalizer from 'utils/InvoiceTypeLocalizer';
import PaymentHistoryProvider from 'utils/PaymentHistoryProvider';
import FullPageSpinner from 'components/FullPageSpinner';
import FormHelper from 'utils/FormHelper';
import XmlHelper from 'utils/XmlHelper';
import ZipHelper from 'utils/ZipHelper';
import FileDownloader from 'utils/FileDownloader';
import EnvelopeGenerator from 'utils/EnvelopeGenerator';

const Invoice = ({ invoiceId, organizationId, invoiceTypeId, fromInvoiceId, history }) => {
  
  const { currentUser, organizationData } = useAuth();
  const invoice = useInvoice(invoiceId, organizationId, invoiceTypeId, currentUser, fromInvoiceId);
  const { register, errors, getValues, handleSubmit } = useForm();
  const snackbar = useSnackbar();
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(undefined);
  const emailTemplate = invoice.getEmailTemplate();

  const onPartnerChanged = (newPartner) => {
    invoice.setPartnerData(newPartner ? newPartner : {});
  };
  
  const saveInvoice = useCallback(async (formData, event) => {
    // Workaround for submit being called on wrong form when adding/editing partner on invoice.
    if (!event.target.classList.contains('invoice')) {
      return;
    }

    if (!invoice.validate()) {
      return;
    }

    invoice.buttonState.setSaveButtonDisabled(true);

    const savedInvoice = await invoice.save(formData);
    
    invoice.buttonState.setSaveButtonDisabled(false);
    
    if (!savedInvoice) {
      snackbar.show({ text: 'Pri shranjevanju računa je prišlo do napake.', severity: 'error' });
      return;
    }

    if (invoice.isNewInvoice) {
      const route = Router.route(Routes.documents, { documentId: savedInvoice.id });
      history.push(route);

      snackbar.show({ text: 'Račun uspešno shranjen.', severity: 'success' });
    } else {
      snackbar.showSaveSuccess();
    }
  }, [ history, invoice, snackbar ]);

  const onEmailSent = (messageIds, recipients) => {
    snackbar.show({ text: 'Račun uspešno poslan.', severity: 'success' });
    invoice.saveInvoiceAfterEmailSent(messageIds, recipients);
    setEmailModalOpen(false);
  };

  const onEmailVerificationLinkSentSuccess = (message) => {
    snackbar.show({ text: message, severity: 'success' }, 6000);
    setEmailModalOpen(false);
  };

  const onEmailVerificationLinkSentError = (message) => {
    snackbar.show({ text: message, severity: 'error' }, 6000);
    setEmailModalOpen(false);
  };

  const handleAttachmentFileUploadError = (fileId, message) => {
    snackbar.show({ text: message, severity: 'error' }, 6000);
    invoice.metadata.setAttachmentErrorMessage(fileId, message);
  };

  if (!invoice.hasData) {
    return (
      <FullPageSpinner text={invoice.progressText} />
    )
  }

  const tryOpenEmailModal = async (event) => {
    event.preventDefault();
    
    var saved = await save(invoice.buttonState.setSendButtonDisabled);
    if (!saved) {
      return;
    }

    setEmailModalOpen(true);
  };

  const tryOpenHistoryModal = async (event) => {
    event.preventDefault();
    
    const paymentHistoryProvider = new PaymentHistoryProvider(invoice.db, currentUser.uid, invoiceId);
    const payments = await paymentHistoryProvider.get();
    setHistoryModalOpen(payments);
  };

  const closeHistoryModal = () => {
    setHistoryModalOpen(undefined);
  };

  const handlePaymentHistoryModalSaveSuccess = (newStatus) => {
    if (newStatus) {
      invoice.metadata.setStatus(newStatus);
    }
  };

  const closeEmailModal = () => {
    setEmailModalOpen(false);
  };
    
  const getPdfAttachment = async () => {
    const pdfSettings = getPdfSettings();
    const base64content = await InvoicePdfGenerator.getPdfBase64(invoice, pdfSettings);
    return getAttachment('pdf', base64content);
  };
  
  const getXmlAttachment = async () => {
    const xmlContent = eSlog20Generator.generateXml(invoice);
    const base64content = await XmlHelper.getXmlBase64(xmlContent);
    return getAttachment('xml', base64content);
  };

  const getAttachment = (fileExtension, base64content) => {
    const name = invoice.getFileName(fileExtension);
    return {
      name: name,
      base64content: base64content
    };
  };

  const saveAndCreateInvoice = async (event) => {
    event.preventDefault();

    var saved = await save(invoice.buttonState.setCreateInvoiceButtonDisabled);
    if (!saved) {
      return;
    }

    invoice.setHasData(false);
    
    const url = Router.route(Routes.documents, { documentId: 'new' }, { organizationId: invoice.organizationData.id, fromInvoiceId: invoiceId });
    history.push(url);
  };

  const saveAndDownloadPdf = async (event) => {
    event.preventDefault();

    var saved = await save(invoice.buttonState.setDownloadPdfButtonDisabled);
    if (!saved) {
      return;
    }
    
    downloadPdf();
  };

  const getPdfSettings = () => {
    return {
      cultureCode: organizationData.cultureCode,
      vatBreakdownVisible: organizationData.visibleVatBreakdownOnPdf && invoice.itemListState.summary.vatPrice > 0,
      qrCodeVisible: organizationData.visibleQrCodeOnPdf
    }
  };

  const downloadPdf = () => {
    const fileName = invoice.getFileName('pdf');
    const pdfSettings = getPdfSettings();
    InvoicePdfGenerator.downloadPdf(invoice, fileName, pdfSettings);
  };

  const saveAndDownloadXml = async (event) => {
    event.preventDefault();
    
    var saved = await save(invoice.buttonState.setDownloadXmlButtonDisabled);
    if (!saved) {
      return;
    }
    
    if (invoice.data.organization.id === '6S3VZKYdiPZWVWZlrG8K' || invoice.data.organization.id === 'ftQFm5qDuJBoI4kfijUr') {
      await downloadEinvoiceAsync();
    }
    else {
      downloadXml();
    }
  };

  const downloadEinvoiceAsync = async () => {
    const envelopeXmlContent = EnvelopeGenerator.generateXml(invoice.data, invoice.itemListState.summary);
    const envelopeXmlFileName = 'Envelope_' + invoice.getFileName('ENV');
    
    const invoiceXmlContent = eSlog20Generator.generateXml(invoice);
    const invoiceXmlFileName = 'eSlog_' + invoice.getFileName('xml');

    const pdfSettings = getPdfSettings();
    const invoicePdfContent = InvoicePdfGenerator.generatePdf(invoice, pdfSettings);
    const invoicePdfFileName = invoice.getFileName('pdf');
    
    const zipFileName = invoice.getFileName('zip');
    const files = [
      {
        blob: XmlHelper.getXmlBlob(envelopeXmlContent),
        fileName: envelopeXmlFileName
      },
      {
        blob: XmlHelper.getXmlBlob(invoiceXmlContent),
        fileName: invoiceXmlFileName
      },
      {
        blob: invoicePdfContent,
        fileName: invoicePdfFileName
      }
    ];
    const zipFile = await ZipHelper.createZipFileAsync(files);
    FileDownloader.downloadFile(zipFile, zipFileName);
  };

  const downloadXml = () => {
    const xmlContent = eSlog20Generator.generateXml(invoice);
    const file = XmlHelper.getXmlBlob(xmlContent);
    const fileName = invoice.getFileName('xml');    
    FileDownloader.downloadFile(file, fileName);
  };

  const saveAndPrintPdf = async (event) => {
    event.preventDefault();

    var saved = await save(invoice.buttonState.setPrintPdfButtonDisabled);
    if (!saved) {
      return;
    }

    const pdfSettings = getPdfSettings();
    InvoicePdfGenerator.printPdf(invoice, pdfSettings);
  };

  const save = async (setButtonDisabled) => {    
    const formData = getValues({ nest: true });    
    
    if (!invoice.validate()) {
      return false;
    }

    setButtonDisabled(true);
    await invoice.save(formData);
    setButtonDisabled(false);

    return true;
  };

  const createInvoiceButton = invoiceId && invoice.metadata.invoiceType.allowsCreatingInvoice ? (
    <button className="buttonish text-only secondary" onClick={saveAndCreateInvoice} disabled={invoice.buttonState.createInvoiceButtonDisabled}>Izdaj račun</button>
  ) : null;
  const savedButtonsHtml = invoiceId ? (
    <>
      <button className="buttonish text-only secondary" onClick={tryOpenEmailModal} disabled={invoice.buttonState.sendButtonDisabled}>Pošlji</button>
      <button className="buttonish text-only secondary" onClick={tryOpenHistoryModal} disabled={invoice.buttonState.paymentHistoryButtonDisabled}>Zgodovina</button>
      {createInvoiceButton}
      <button className="buttonish text-only secondary" onClick={saveAndDownloadPdf} disabled={invoice.buttonState.downloadPdfButtonDisabled}>Prenesi kot PDF</button>
      <button className="buttonish text-only secondary" onClick={saveAndDownloadXml} disabled={invoice.buttonState.downloadXmlButtonDisabled}>Prenesi kot e-račun</button>
      <button className="buttonish text-only secondary" onClick={saveAndPrintPdf} disabled={invoice.buttonState.printPdfButtonDisabled}>Natisni</button>
    </>
  ) : null;

  return (
    <form className="page invoice" onSubmit={handleSubmit(saveInvoice)} onKeyDown={(e) => FormHelper.preventSubmitOnEnterKey(e)}>
      <div className="invoice-metadata column r-float">
        <InvoiceMetadata metadata={invoice.metadata} organization={invoice.organizationData} attribution={invoice.data.attribution} onOrganizationDataChanged={invoice.setOrganizationData} />
      </div>

      <div className="line-label no-margin l-float clear-l-float">
        Izdajatelj
      </div>
      <div className="invoice-partner column l-float clear-l-float">
        <InvoiceOrganization name="organization" organizationData={invoice.organizationData} register={register} />
      </div>

      <div className="line-label no-margin l-float clear-l-float">
        Partner
      </div>
            
      <div className="invoice-partner column l-float clear-l-float">
        <PartnerTextBox name="partner" partnerData={invoice.partnerData} onChanged={onPartnerChanged} errors={errors} register={register} />
      </div>
      
      <div className="clear-float" />
      
      <div className="invoice-center">
        <h1 className="invoice-item-list-header hide-on-mobile">
          {InvoiceTypeLocalizer.getText(invoice.metadata.invoiceType.id)}
        </h1>
        <InvoiceItemList
          currency={invoice.metadata.currency}
          data={invoice.itemListState.models}
          summary={invoice.itemListState.summary}
          isAddButtonEnabled={invoice.itemListState.isAddButtonEnabled()}
          onAddButtonClicked={invoice.itemListState.addItem}
          onRemoveButtonClicked={invoice.itemListState.removeItem}
          onNameChanged={invoice.itemListState.changeItemName}
          onUnitChanged={invoice.itemListState.changeItemUnit}
          onQuantityChanged={invoice.itemListState.changeItemQuantity}
          onPriceChanged={invoice.itemListState.changeItemPrice}
          onVatPercentageChanged={invoice.itemListState.changeItemVatPercentage}
          onDiscountPercentageChanged={invoice.itemListState.changeItemDiscountPercentage}
          taxDescriptions={invoice.itemList.taxDescriptions}
          taxDescription={invoice.itemList.taxDescription}
          onTaxDescriptionChange={invoice.itemList.setTaxDescription} />
      </div>
      
      <div className="invoice-center">
        <div className="line-label">
          Priponke
        </div>
        <InvoiceAttachmentList organizationId={invoice.metadata.organizationId} attachments={invoice.metadata.attachments} onUploadStarted={invoice.metadata.addAttachment} onUploadProgress={invoice.metadata.setAttachmentUploadProgress} onUploadSuccess={invoice.metadata.markAttachmentAsUploaded} onUploadError={handleAttachmentFileUploadError} onRemoved={invoice.metadata.removeAttachment} />
      </div>

      <RelatedDocumentList fromInvoiceId={invoice.data.fromInvoiceId} fromInvoiceNumberText={invoice.metadata.fromInvoiceNumberText} successorInvoices={invoice.data.successorInvoices} />
            
      <div className="invoice-attribution column l-float clear-l-float">
        <div className="line-label">
          Pripis
        </div>
        <Attribution name="attribution" value={invoice.metadata.attribution} onChange={invoice.metadata.setAttribution} />
      </div>

      <div className="invoice-signature column r-float">
        <SignatureBox textBoxName="signature" fileUrlName="signatureFileUrl" label="Podpis" defaultTextValue={invoice.data.signature} defaultFileUrlValue={invoice.data.signatureFileUrl} organizationId={invoice.metadata.organizationId} register={register} />
      </div>

      <div className="clear-float" />

      <div className={`button-bottom-bar r-align ${invoice.saving ? 'locked' : ''}`}>
        {savedButtonsHtml}
        <button className="buttonish text-only" disabled={invoice.buttonState.saveButtonDisabled} type="submit">Shrani</button>
      </div>

      <div id="invisible-print-container" />

      {snackbar.render()}

      <EmailModal
      open={emailModalOpen}
      onClose={closeEmailModal}
      onSent={onEmailSent}
      onEmailVerificationLinkSentSuccess={onEmailVerificationLinkSentSuccess}
      onEmailVerificationLinkSentError={onEmailVerificationLinkSentError}
      sender={emailTemplate.sender}
      recipients={emailTemplate.recipients}
      subject={emailTemplate.subject}
      body={emailTemplate.body}
      attachments={emailTemplate.attachments}
      getPdfAttachment={getPdfAttachment}
      getXmlAttachment={getXmlAttachment}
      downloadPdfAttachment={downloadPdf}
      downloadXmlAttachment={downloadXml}
      accountingEmail={invoice.organizationData.accountingEmail}
      invoiceId={invoiceId}
      />

      <InvoiceHistoryModal
      open={historyModalOpen !== undefined}
      payments={historyModalOpen}
      onPaymentHistorySave={handlePaymentHistoryModalSaveSuccess}
      onClose={closeHistoryModal}
      invoiceId={invoiceId}
      emailMessageIds={invoice.data.sentEmailMessageIds ? invoice.data.sentEmailMessageIds : []}
      priceSum={invoice.itemListState.summary.priceSum}
      currency={invoice.metadata.currency} />

    </form>
  );
};

export default Invoice;
